/* Bootstrap Variables */
$primary: #000;
$secondary: #fff;
$tertiary: #ff2d2e;
$quaternary: #bcfc07;

$body-bg: #000;
$body-color: #fff;

$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'tertiary': $tertiary,
    'quaternary': $quaternary,
);
