@import '../theme/breakpoints.scss';

@font-face {
    font-family: 'CasperSansHubspot';
    src: url('../../assets/fonts/CasperSans/CasperSans-Regular.woff2') format('woff2'),
        url('../../assets/fonts/CasperSans/CasperSans-Regular.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

main {
    margin-top: 7rem;
    @media (max-width: $mobile) {
        margin-top: 6rem;
    }
}

/* Typography Overrides */

$headings-font-weight: 300;
$font-size-base: 1.2rem;
$h1-font-size: 6rem;
$h2-font-size: 6rem;
$h3-font-size: 3.375rem;
$h4-font-size: 2.125rem;
$h6-font-size: 1.675rem;

h1,
.h1 {
    font-size: calc(96px + (130 - 96) * ((100vw - 1440px) / (2560 - 1440))) !important;
    letter-spacing: normal !important;
}
h2,
.h2 {
    font-size: calc(66px + (86 - 66) * ((100vw - 1440px) / (2560 - 1440))) !important;
    letter-spacing: 1.62px !important;
}
h3,
.h3 {
    font-size: calc(54px + (70 - 54) * ((100vw - 1440px) / (2560 - 1440))) !important;
    letter-spacing: 1.62px !important;
}
h4,
.h4 {
    font-size: calc(42px + (54 - 42) * ((100vw - 1440px) / (2560 - 1440))) !important;
    letter-spacing: 3.36px !important;
}
h5,
.h5 {
    font-size: calc(34px + (44 - 34) * ((100vw - 1440px) / (2560 - 1440))) !important;
}
h6,
.h6 {
    font-size: calc(27px + (34 - 27) * ((100vw - 1440px) / (2560 - 1440))) !important;
}

p {
    letter-spacing: 2px !important;
    @media (min-width: $desktop) {
        font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (2560 - 1440))) !important;
    }
}
blockquote {
    margin: 1.5em 10px !important;
    padding: 0.5em 10px !important;
    quotes: '\201C''\201D''\2018''\2019';
    @media (max-width: $desktop) {
        padding: 0.5em 30px !important;
    }
    @media (max-width: $mobile) {
        margin: 3.75em 10px !important;
        padding: 0 !important;
    }
    p {
        font-size: calc(42px + (54 - 42) * ((100vw - 1440px) / (2560 - 1440))) !important;
        letter-spacing: 3.36px !important;
        line-height: 45.36px;
        display: inline;
    }
}

blockquote:before {
    color: #ff2d2e;
    content: open-quote;
    font-size: 6em;
    line-height: 0.1em;
    margin-left: -0.45em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
    @media (max-width: $mobile) {
        line-height: 0.4em;
        margin-left: unset;
        vertical-align: 0em;
        display: block;
        height: 1.25rem;
    }
}

blockquote:after {
    color: #ff2d2e;
    content: close-quote;
    font-size: 6em;
    line-height: 0.1em;
    margin-right: 0.25em;
    margin-left: 0.15em;
    vertical-align: bottom;
    @media (max-width: $mobile) {
        line-height: 8rem;
        margin-right: 0.25em;
        margin-left: unset;
        display: block;
        height: 1.25rem;
    }
}

hr {
    opacity: 1 !important;
}
/* Spacing */

$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 5,
    7: $spacer * 8,
    8: $spacer * 10,
    9: $spacer * 12,
);

/* Containers */

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1220px,
);

@media (min-width: 1400px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: calc(1180px + (1800 - 1180) * ((100vw - 1440px) / (2560 - 1440))) !important;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn !important;
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
    animation: fadein 3s;
}

/* Components Overrides */

/* Navigation */

$navbar-light-color: $body-color;
$navbar-light-active-color: $body-color;
$navbar-light-hover-color: $body-color;
$link-color: $body-color;
$navbar-padding-y: 1.25rem;
$navbar-nav-link-padding-x: 1rem;

$dropdown-link-color: $body-color;
$dropdown-bg: black;
$dropdown-link-hover-bg: black;
$dropdown-link-hover-color: $body-color;
$offcanvas-bg-color: black;

.navbar-toggler {
    border: 0 !important;
    &:focus {
        box-shadow: unset !important;
    }
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
    transition: 11s all;
}

.btn-close {
    color: #000;
    opacity: 1 !important;
    background: transparent url('/icons/close-btn.webp') center/2rem auto no-repeat !important;
}

.navbar {
    @media (max-width: $mobile) {
        padding: 1rem 0 !important;
    }
    transition: 0.3s all ease;
    a {
        font-size: calc(0.875rem + 0.3vw) !important;
        @media (min-width: 992px) and (max-width: 1400px) {
            font-size: 14px !important;
        }
        line-height: 2;
        letter-spacing: 1.6px;
    }
    svg {
        width: auto;
        height: 4rem;
        @media (max-width: $tablet) {
            height: 2.5rem;
        }
        transition: 0.3s all ease;
    }
    .dropdown-toggle::after {
        margin-left: 0;
        vertical-align: -0.2em;
        @media (min-width: 1200px) and (max-width: 1400px) {
            vertical-align: -0.3em;
        }
        content: '';
        display: inline-block;
        width: calc(20px + (25 - 20) * ((100vw - 1440px) / (2560 - 1440)));
        height: calc(20px + (25 - 20) * ((100vw - 1440px) / (2560 - 1440)));
        background-image: url(/icons/menu-arrow.png);
        background-size: cover;
        margin-left: 0;
        border-top: 0;
        border-right: 0;
        border-bottom: 0;
        border-left: 0;
    }
    .nav-link {
        @media (max-width: $desktop) {
            padding-left: 0 !important;
            padding-right: 1rem !important;
        }
    }
    .navbar-nav {
        align-items: baseline;
    }
}

@media (min-width: $tablet) {
    .sub-dropdown a:not(.dropdown-item) {
        padding: 0 !important;
    }
}

.sub-dropdown .dropdown-menu {
    left: 9.5rem !important;
    top: -0.5rem !important;
}

@media (min-width: $tablet) {
    .sub-dropdown .dropdown-toggle::after {
        width: calc(20px + (25 - 20) * ((100vw - 1440px) / (2560 - 1440)));
        height: calc(20px + (25 - 20) * ((100vw - 1440px) / (2560 - 1440)));
        background-image: url(/icons/sub-menu-arrow.png);
        background-size: cover;
        margin-left: 0;
        border-top: 0;
        border-right: 0;
        border-bottom: 0;
        border-left: 0;
        vertical-align: -0.2em;
    }
}

.hs-contact-btn {
    background-color: #3edc64;
    padding: 0 1rem;
    letter-spacing: 0.72px;
    display: flex;
    align-items: center;
    border-radius: 6px;

    @media (max-width: $tablet) {
        padding: 0.3rem 1rem;
        width: 8rem;
        margin-top: 0.5rem;
    }

    a {
        text-decoration: none;
    }
}

.navbar-toggler-icon {
    width: 3rem !important;
    height: 3rem !important;
    background-image: url('/icons/navbar-toggle-icon.webp') !important;
}

.navbarOnScroll {
    &.navbar {
        padding: 2rem 0;
    }
    svg {
        @media (min-width: $desktop) {
            width: auto;
            height: 4rem;
        }
        transition: 0.3s all ease;
    }
    background-color: black;
    transition: 0.3s all ease;
}

/* Accordion */

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='29.25' height='29.25' viewBox='0 0 29.25 29.25'> <path id='Trazado_213' data-name='Trazado 213' d='M25.875,29.1V42.6h2.25V29.1h13.5v-2.25h-13.5v-13.5h-2.25v13.5h-13.5V29.1Z' transform='translate(-12.375 -13.355)' fill='#fff' fill-rule='evenodd'/> </svg>");

$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='29.25' height='2.25' viewBox='0 0 29.25 2.25'> <path id='Trazado_214' data-name='Trazado 214' d='M12.375,26.855h29.25V29.1H12.375Z' transform='translate(-12.375 -26.855)' fill='#fff' fill-rule='evenodd'/> </svg>");

$accordion-border-width: 0;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: map-get($theme-colors, 'quaternary');

.AccordionComponent {
    .accordion-header {
        button {
            font-size: calc(66px + (86 - 66) * ((100vw - 1440px) / (2560 - 1440))) !important;
            @media (max-width: $mobile) {
                font-size: 2rem !important;
            }
            @media (max-width: $tablet) {
                font-size: 3rem;
            }
            font-weight: 200 !important;
            padding: 0;
            letter-spacing: 1.5px;
            background-color: transparent;
            box-shadow: none;
        }
    }
    .accordion-item {
        padding: 2rem 0;
        border-bottom: 1px solid #434343;
        background-color: transparent;
    }
    .accordion-body {
        font-size: calc(27px + (37 - 27) * ((100vw - 1440px) / (2560 - 1440))) !important;
        letter-spacing: 1.3px;
        padding: 2rem 0 0;
    }
}

/* Modals */

$modal-content-bg: black;
$modal-content-border-width: 0;

/* Buttons */
$link-hover-color: #fff;

$border-radius: 0px;
$input-btn-font-size: 0.875rem;
$btn-font-weight: 800;
$btn-font-size: calc(14px + (16 - 14) * ((100vw - 1440px) / (2560 - 1440)));
$btn-padding-y: 0.75rem;

@mixin arrow-color($color) {
    content: url('data:image/svg+xml;utf8,<svg width="12" height="11" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.997559 4.00021H9.00205M9.00205 4.00021L5.8578 0.855957M9.00205 4.00021L5.8578 7.14447" stroke="#{$color}" /> </svg>');
}

.btn {
    text-transform: uppercase;
    padding-right: 1.3rem !important;
    padding-left: 1rem !important;
    letter-spacing: 0.72px !important;
    &::after {
        margin-left: 0.5rem;
        transition: transform 0.3s;
        display: inline-block;
    }

    &:hover {
        &::after {
            transform: translateX(0.75rem);
        }
    }
    @media (max-width: $mobile) {
        width: 100%;
    }
}

.btn-primary {
    &::after {
        @include arrow-color(white);
    }
}
.btn-secondary {
    &::after {
        @include arrow-color(black);
    }
}

.btn-link {
    &::after {
        @include arrow-color(white);
    }
    padding: 0 !important;
    text-decoration: none !important;
}

.btn-outline {
    border: 1px solid white !important;

    &::after {
        @include arrow-color(white);
        color: white;
    }
}

/* Footer */

.footer {
    background-color: black;
    color: white;
    p,
    a,
    li {
        @media (min-width: $desktop) {
            font-size: calc(16px + (22 - 16) * ((100vw - 1440px) / (2560 - 1440))) !important;
        }
    }
    a {
        color: white;
    }
    .footer-logo {
        svg {
            width: auto;
            height: calc(64px + (84 - 64) * ((100vw - 320px) / (2560 - 320)));
        }
    }
    .social-media {
        svg {
            width: auto;
            height: calc(26px + (36 - 26) * ((100vw - 320px) / (2560 - 320)));
            cursor: pointer;
            &:hover {
                path {
                    fill: map-get($theme-colors, 'quaternary');
                }
            }
        }
        @media (max-width: $mobile) {
            margin: 0 auto;
        }
    }
    .category {
        font-weight: 600;
    }

    .copyright {
        @media (max-width: $mobile) {
            margin: 0 auto;
            text-align: center;
        }
        .d-flex {
            @media (max-width: $mobile) {
                flex-direction: column;
            }
        }
        a {
            margin-left: 6rem;
            @media (max-width: $mobile) {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

.PageLoader {
    svg {
        width: 24rem !important;
        height: auto !important;
    }
}

/* Hero */

.Hero {
    .row {
        background-color: #141414;
    }
    .content {
        padding: 2rem;
        @media (min-width: $desktop) {
            padding: 0 calc(50px + (80 - 50) * ((100vw - 1440px) / (2560 - 1440))) 0
                calc(140px + (400 - 140) * ((100vw - 1440px) / (2560 - 1440))) !important;
        }
    }
    .childrenContent {
        height: calc(669px + (869 - 669) * ((100vw - 1440px) / (2560 - 1440)));
        @media (max-width: $tablet) {
            height: 25rem;
        }
        @media (max-width: $mobile) {
            height: 19rem;
        }
    }
}

/* CardHorizontal4 */

.CardHorizontal4 {
    .card {
        border-radius: 0.5rem;
        color: #000;
        padding: 0rem 2.5rem;
        min-height: calc(340px + (500 - 340) * ((100vw - 1440px) / (2560 - 1440)));
        @media (max-width: $desktop) {
            height: 20rem;
            padding: 1rem;
        }
    }
    .card-body {
        justify-content: center !important;
        &:hover {
            .desc {
                opacity: 1;
                max-height: 500px;
                transition: opacity 0.5s ease-out, max-height 0.8s ease-out;
            }
            .open-arrow {
                opacity: 0;
            }
        }
        .title {
            font-size: calc(42px + (54 - 42) * ((100vw - 1440px) / (2560 - 1440))) !important;
            letter-spacing: 3.36px !important;
            line-height: 1;
        }
        .desc {
            font-size: 16px !important;
            max-height: 0;
            opacity: 0;
            transition: opacity 0.1s ease-out, max-height 0.8s ease-out;
        }
        .open-arrow {
            opacity: 1;
            bottom: 2rem;
            @media (min-width: $desktop) {
                display: none;
            }
        }
    }
}

/* CTA */

.ctaRight {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),
        linear-gradient(266.56deg, #f622e6 31.27%, #ff2d2e 105.32%);
    padding-block: calc(80px + (100 - 80) * ((100vw - 1440px) / (2560 - 1440))) !important;
    .align-items-center {
        @media (min-width: $desktop) {
            align-items: end !important;
        }
    }
    h3 {
        font-size: calc(42px + (54 - 42) * ((100vw - 1440px) / (2560 - 1440))) !important;
        letter-spacing: 3.36px !important;
    }
    &.ctaPurple {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),
            linear-gradient(266.56deg, #9f23e1 31.27%, #5a0994 105.32%);
    }
}

.CTARight4 {
    .cta-panel {
        background-color: black;
        color: white;
        hr {
            border-top: 1px solid white;
            margin: 2rem 0;
            opacity: 1;
        }
    }
}

/* Logo Slider */

.logosSliderSection {
    background: linear-gradient(180deg, #cacaca 0%, #fff 100%);
    color: black;
    svg {
        width: calc(42px + (56 - 42) * ((100vw - 1440px) / (2560 - 1440)));
        height: auto;
    }
}

.logosSlider {
    padding: 0 !important;
    background: transparent !important;
    img {
        width: auto;
        height: auto;
        max-width: 200px;
        max-height: calc(88px + (100 - 88) * ((100vw - 1440px) / (2560 - 1440)));
    }
}

/* Banner Slider */

.bannerSlider {
    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,<svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M6 20.8486L13.8514 12.9801L6 5.11148L8.41714 2.69434L18.7029 12.9801L8.41714 23.2658L6 20.8486Z' fill='white'/></svg>");
    }
    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,<svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M18.7029 20.8486L10.8514 12.9801L18.7029 5.11148L16.2857 2.69434L6 12.9801L16.2857 23.2658L18.7029 20.8486Z' fill='white'/></svg>");
    }
    .carousel-control-prev {
        justify-content: end;
        width: 8%;
        opacity: 1;
        @media (max-width: $mobile) {
            align-items: end !important;
            width: 48% !important;
            bottom: 2rem !important;
        }
    }
    .carousel-control-next {
        justify-content: start;
        width: 8%;
        opacity: 1;
        @media (max-width: $mobile) {
            align-items: end !important;
            width: 48% !important;
            bottom: 2rem !important;
        }
    }
}

.bannerSliderHideArrow {
    .carousel-control-prev {
        display: none;
    }
    .carousel-control-next {
        display: none;
    }
}

/* Video Player */

.videoPlayer {
    .poster {
        height: calc(400px + (600 - 400) * ((100vw - 1440px) / (2560 - 1440)));
        background-size: cover;
        background-position: center;
        width: 100%;
        background-repeat: no-repeat;
    }
}

#videoPlayer {
    > div {
        width: 100% !important;
    }
    .react-player__shadow {
        background: url('/img/player-icon.png') !important;
        background-size: cover !important;
    }
    .lty-playbtn {
        border: none !important;
    }
}

.play-btn {
    width: calc(66.4px + (90 - 66) * ((100vw - 1440px) / (2560 - 1440))) !important;
    height: auto;
}

/* Modal Video Player */

#modalVideo {
    max-width: 100% !important;
    height: 100%;
    margin: 0 auto;
    .modal-body {
        display: flex;
        align-items: center;
        @media (min-width: $tablet) {
            padding: 0 calc(8rem + 7vw);
        }
        > div {
            width: 100% !important;
            @media (min-width: $tablet) {
                height: calc(800px + (1200 - 800) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }
    }
    .modal-content {
        height: 100%;
        background-color: transparent !important;
    }
    .modal-header {
        padding-right: 0;
        .btn-close {
            position: fixed;
            height: calc(54px + (64 - 54) * ((100vw - 1440px) / (2560 - 1440))) !important;
            width: calc(54px + (64 - 54) * ((100vw - 1440px) / (2560 - 1440))) !important;
            padding: 0;
            top: 0.5rem;
            right: 0.5rem;
            background-size: contain !important;
        }
    }
}

// Content Full Screnn

.ContentRight {
    overflow: hidden;
    background: #3edc64;
    padding-block: calc(150px + (200 - 150) * ((100vw - 1440px) / (2560 - 1440)));
    color: black;
    @media (max-width: 1200px) {
        padding-bottom: 0px;
    }
    @media (max-width: $mobile) {
        padding-bottom: 1.5rem;
    }
    img {
        width: 100%;
        height: auto;
    }
    &.getStarted {
        background: #bcfc07;
        color: #000;
        img {
            width: calc(620px + (900 - 620) * ((100vw - 1440px) / (2560 - 1440))) !important;
            @media (max-width: $mobile) {
                width: 100% !important;
            }
            top: calc(-280px + -100 * (100vw - 1440px) / 1120);
            @media (max-width: $desktop) {
                position: absolute;
                top: 0;
                margin: 0 auto;
                position: relative;
                width: 100%;
            }
        }
        .contentRightContent {
            text-align: center;
        }
    }
    &.firstBlockhain {
        background: #3edc64;

        img {
            width: 100%;
        }
    }
}

/* Home Components Overrides */

.homePage {
    .heroFullCasper {
        h1 {
            margin-bottom: 1rem;
        }
        p {
            margin-bottom: 2rem;
        }
    }
    .ContentRight {
        h2 {
            font-size: calc(96px + (130 - 96) * ((100vw - 1440px) / (2560 - 1440))) !important;
        }
    }
    .videoPlayerHome {
        height: calc(600px + (1000 - 600) * ((100vw - 1440px) / (2560 - 1440))) !important;
        .react-player__preview {
            height: calc(600px + (1000 - 600) * ((100vw - 1440px) / (2560 - 1440))) !important;
        }
    }

    .AccordionComponent {
        .accordion-item {
            padding-top: 0 !important;
        }
        .accordion-button {
            font-size: calc(14px + (20 - 14) * ((100vw - 1440px) / (2560 - 1440))) !important;
            letter-spacing: 3.36px !important;
            width: auto !important;
            line-height: 1.5;
        }
        .accordion-button::after {
            margin-left: 1rem;
            background-size: 14px;
            background-position: center;
        }
        .accordion-button:not(.collapsed)::after {
            height: 2px !important;
        }
        .accordion-item {
            border-bottom: 0;
        }
    }

    .videoTranscriptPreview {
        line-height: 1.25em;
        max-height: 5em;
        font-size: calc(27px + 10 * (100vw - 1440px) / 1120) !important;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
    }

    #ebook {
        section {
            height: calc(676px + (915 - 676) * ((100vw - 1440px) / (2560 - 1440))) !important;
            @media (max-width: $desktop) {
                height: calc(690px + (1180 - 676) * ((100vw - 320px) / (1199.98 - 320))) !important;
            }
        }
    }
    #careers {
        section {
            @media (max-width: $desktop) {
                height: 1100px !important;
            }
        }
    }
}

.heroFullCasper {
    .blocks-animation {
        > div {
            @media (min-width: $desktop) {
                position: absolute;
                right: -2rem;
            }
            @media (max-width: $desktop) {
                position: absolute;
                right: 2rem;
            }
            @media (max-width: $tablet) {
                position: absolute;
                right: -3rem !important;
            }
            @media (max-width: $tablet) {
                position: absolute;
                right: -3rem !important;
            }
            @media (max-width: $mobile) {
                position: relative;
                right: 0 !important;
            }
        }
        svg {
            @media (min-width: $desktop) {
                width: calc(700px + (900 - 700) * ((100vw - 1440px) / (2560 - 1440))) !important;
                height: calc(600px + (900 - 700) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
            @media (min-width: $mobile) and (max-width: $desktop) {
                width: calc(369px + (400 - 369) * ((100vw - 320px) / (1199 - 320))) !important;
            }
        }
    }
}

.ContentCenter {
    .video-container {
        hr {
            margin: 3rem 0;
            opacity: 1;
            width: 20%;
        }
        .poster {
            @media (min-width: $tablet) {
                width: 370px;
                height: 210px;
            }
        }
        @media (min-width: $tablet) {
            display: flex;
            justify-content: center;
        }
    }
}

.careersSection {
    .content {
        @media (max-width: $tablet) {
            transform: translate(0, -80px);
        }
        .text {
            @media (min-width: $tablet) {
                padding-left: 10%;
            }
            @media (max-width: $tablet) {
                padding: 1rem;
            }
        }
    }
    img {
        @media (min-width: $tablet) {
            position: sticky;
            height: auto;
            top: 10rem;
            width: 52rem;
        }
    }
}

@mixin cardContentAnimation {
    .cardContent {
        width: calc(480px + (480 - 480) * ((100vw - 1440px) / (2560 - 1440)));
        @media (max-width: $mobile) {
            width: 22ch;
        }
        animation: fadeIn 2s;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.slideCasper {
    position: relative;
    overflow: hidden;
    &::after {
        content: '';
        background-image: linear-gradient(to right, #2230e6 0%, #f9aaca 53%, #fff 100%);
        border-radius: 50%;
        filter: blur(200px);
        position: absolute;
        left: 0rem;
        top: 35rem;
        width: 1440px;
        height: 665px;
        z-index: -1;
        opacity: 0.3;
    }
    .react-multi-carousel-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        z-index: 999;
    }
    .react-multi-carousel-track {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        position: relative;
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        will-change: transform, transition;
    }
    .react-multi-carousel-item {
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    .react-multi-carousel-item {
        transition: 1s all;
    }
    .react-multi-carousel-list {
        overflow-x: scroll;
        .carouselCard {
            background-color: #141414;
            color: white;
            border: 1px solid #595959 !important;
            border-radius: 8px;
            cursor: pointer;
            .cardTitle {
                writing-mode: vertical-rl;
                text-orientation: unset;
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
            }
            svg {
                width: 6rem;
                height: auto;
            }
        }
        .cardActive {
            width: 675px !important;
            transition: 1s all;
            @media (max-width: $mobile) {
                width: 95vw !important;
            }
            .card-body {
                justify-content: space-between !important;
                align-items: flex-start !important;
            }
        }
        .carouselCard0 {
            background: linear-gradient(0deg, #9f23e1 0.08%, #27222f 100.08%);
            border-color: #9f23e1 !important;
        }
        .carouselCard1 {
            background: linear-gradient(0deg, #2230f0 0.08%, #27222f 100.08%);
            border-color: #2230f0 !important;
        }
        .carouselCard2 {
            background: linear-gradient(0deg, #f622e6 0.08%, #27222f 100.08%);
            border-color: #f622e6 !important;
        }
        .carouselCard3 {
            background: linear-gradient(0deg, #f9aaca 0.08%, #27222f 100.08%);
            border-color: #f9aaca !important;
        }
        .carouselCard4 {
            background: linear-gradient(0deg, #4f282b 0.08%, #27222f 100.08%);
            border-color: #4f282b !important;
        }
        .carouselCard5 {
            background: linear-gradient(0deg, #3edc64 0.08%, #27222f 100.08%);
            border-color: #3edc64 !important;
        }
        .carouselCardActive0 {
            .carouselCard {
                background: linear-gradient(0deg, #9f23e1 0.08%, #5a0994 100.08%) !important;
            }
            @include cardContentAnimation;
        }
        .carouselCardActive1 {
            .carouselCard {
                background: linear-gradient(0deg, #2230f0 0.08%, #161f9d 100.08%) !important;
                animation: taadaa 1s;
            }
            @include cardContentAnimation;
        }
        .carouselCardActive2 {
            .carouselCard {
                background: linear-gradient(0deg, #f622e6 70%, #a807a6 100%) !important;
                color: #000 !important;
            }
            @include cardContentAnimation;
        }
        .carouselCardActive3 {
            .carouselCard {
                background: linear-gradient(0deg, #f9aaca 0%, #ad6587 100%) !important;
                color: #000 !important;
            }
            @include cardContentAnimation;
        }
        .carouselCardActive4 {
            .carouselCard {
                background: linear-gradient(0deg, #4f282b 0%, #291315 100%) !important;
            }
            @include cardContentAnimation;
        }
        .carouselCardActive5 {
            .carouselCard {
                background: linear-gradient(0deg, #3edc64 0%, #1a8f3d 100%) !important;
                color: #000 !important;
            }
            @include cardContentAnimation;
        }
    }

    .carouselDragging {
        cursor: grabbing !important;
        .carouselCard {
            cursor: grabbing !important;
        }
    }

    .react-multi-carousel-list::-webkit-scrollbar {
        height: 5px;
    }

    .react-multi-carousel-list::-webkit-scrollbar-thumb {
        height: 5px;
        background-color: #fff;
    }

    .react-multi-carousel-list::-webkit-scrollbar-thumb:hover {
        background-color: #fff;
    }

    .react-multi-carousel-list::-webkit-scrollbar:vertical {
        display: none;
    }

    .carouselScrollLine {
        background-color: #fff;
        height: 1px;
        width: 100%;
        position: relative;
        top: -3px;
        z-index: -10;
    }
}

/* react-multi-carousel-list correspond to a 3rd part component */
.carouselCasper {
    @media (max-width: $mobile) {
        padding-bottom: 1.5rem;
    }
    hr {
        border-top: 1px solid white;
        opacity: 1;
    }
    .react-multi-carousel-list {
        @media (max-width: $mobile) {
            margin-left: unset;
            margin-right: unset;
        }
        z-index: 999;
        .carouselCard {
            background-color: #141414;
            color: white;
            border: 1px solid #595959 !important;
            border-radius: 8px;
            height: calc(350px + (400 - 343) * ((100vw - 320px) / (2560 - 320)));
            @media (max-width: $mobile) {
                height: calc(343px + (400 - 350) * ((100vw - 320px) / (2560 - 320))) !important;
            }
            .carouselButton {
                background-color: transparent !important;
                border-color: transparent;
                border: none;
                padding-left: 0rem !important;
                @media (max-width: $mobile) {
                    width: unset;
                }
            }
            :hover {
                background-color: #bcfc07;
                color: black;
                border: 1px solid 3edc64 !important;
                border-radius: 8px;
                svg {
                    path {
                        fill: black;
                    }
                }
                .carouselButton {
                    color: black;
                    &::after {
                        @include arrow-color(black);
                    }
                }
            }
        }
    }
}

/* Pages Overrides */

.inTheNews {
    .newsHero {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #141414;
        @media (max-width: $mobile) {
            background: unset;
            padding-left: 1rem;
        }
    }
    .newsPosts {
        .line {
            margin: -0.05rem 0;
            border-top: 1px solid #434343;
        }
        .BadgeList {
            span {
                background-color: transparent !important;
                padding: 1rem;
                border-radius: 0;
                font-weight: 300;
                font-size: 18px !important;
                letter-spacing: 1.6px;
                &.active {
                    border-bottom: 1px solid red;
                }
            }
        }
    }
}

.blogPage {
    .blogHero {
        .header-position´ {
            @media (min-width: $desktop) {
                transform: translateY(-25px);
            }
        }
    }
    .ContentFullScreen2 {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
    }
    .featuredBlog {
        @media (max-width: $mobile) {
            img {
                height: 16rem;
                object-fit: cover;
            }
            margin: 0.1rem;
            border-radius: 0.5rem;
            overflow: hidden;
            .featuredContent {
                padding: 1.5rem !important;
            }
        }
    }
    .BlogVertical {
        @media (min-width: $mobile) {
            border-radius: 0.5rem;
            overflow: hidden;
        }

        h4,
        p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        p {
            color: #d9d9d9;
            @media (min-width: $desktop) {
                font-size: 1rem !important;
            }
        }
        span {
            color: white !important;
        }
        a {
            color: #ff2d2e !important;
            span {
                color: #ff2d2e !important;
            }
        }
        .half-title {
            color: map-get($theme-colors, 'tertiary') !important;
            margin-bottom: 2rem;
        }
        .featuredContent {
            background-color: #141414;
            padding: 3rem;
            @media (max-width: $mobile) {
                padding: 1rem;
            }
        }
        .featuredImage {
            padding: 0;
            img {
                @media (min-width: $mobile) {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }
    }
    .recentPosts {
        hr {
            opacity: 0.75;
        }
        .BadgeList {
            span {
                background-color: transparent !important;
                border-bottom: 1px solid #434343;
                padding: 1rem 1.5rem 1rem;
                border-radius: 0;
                font-weight: 300;
                font-size: 1rem !important;
                letter-spacing: 1.6px;
                &.active {
                    border-bottom: 1px solid red;
                }
            }
        }
        .Search {
            border: 0 !important;
            border-bottom: 2px solid red !important;
            svg {
                width: 1.5rem;
                height: auto;
            }
            .form-control {
                padding: 0.375rem 0;
                border: 0;
                font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (2560 - 1440))) !important;
                &::placeholder {
                    color: white;
                    letter-spacing: 0.1rem;
                    font-weight: 300;
                }
                &:-ms-input-placeholder {
                    color: white;
                    letter-spacing: 0.1rem;
                    font-weight: 300;
                }
                &::-ms-input-placeholder {
                    color: white;
                    letter-spacing: 0.1rem;
                    font-weight: 300;
                }
            }
        }
    }
    .newsLetter2 {
        h3 {
            margin-bottom: 0.5rem !important;
        }
        padding: calc(50px + (80 - 50) * ((100vw - 1440px) / (2560 - 1440)))
            calc(25px + (55 - 25) * ((100vw - 1440px) / (2560 - 1440))) !important;
        border-radius: 0.5rem;
        .col-md-6 {
            @media (max-width: $tablet) {
                width: 100% !important;
            }
        }
    }
    .BusinessSpeed {
        @media (min-width: $mobile) {
            border-radius: 0.5rem;
            overflow: hidden;
        }
        h2 {
            font-size: calc(96px + (130 - 96) * ((100vw - 1440px) / (2560 - 1440))) !important;
        }
        p {
            color: #d9d9d9;
            @media (min-width: $desktop) {
                font-size: calc(16px + (20 - 16) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }

        .featuredContent {
            background-color: #141414;
            padding: 4rem 8rem;
            @media (max-width: $tablet) {
                padding: 2rem;
            }
        }
        .featuredImage {
            padding: 0;
            img {
                @media (min-width: $mobile) {
                    object-fit: cover;
                    height: 100%;
                }
            }
        }
    }
}

.blogDetailPage {
    overflow: hidden;
    h2 {
        line-height: 1.08;
    }
    .half-title {
        color: white !important;
        text-transform: capitalize;
    }
    .blogDetailPost {
        padding-bottom: calc(100px + (130 - 100) * ((100vw - 1440px) / (2560 - 1440))) !important;
        @media (max-width: $tablet) {
            padding-bottom: 2.5rem;
        }
        span {
            color: white !important;
        }
        a {
            color: #ff2d2e !important;
            span {
                color: #ff2d2e !important;
            }
        }
        .body {
            color: white;
            ul {
                list-style: unset;
                margin-left: 2rem;
            }
            img {
                width: 100% !important;
            }
        }
        .lineBodyEnd {
            border: 1px solid #ff2d2e;
            width: 170px;
            margin-top: 5rem;
            @media (max-width: $desktop) {
                display: none;
            }
        }
    }
    .relatedPosts {
        background-color: #1f1f1f;
        @media (min-width: $desktop) {
            padding-top: 80px;
            padding-bottom: 100px;
        }
        h5 {
            margin-bottom: 2rem;
        }
    }
}

.BlogHorizontal {
    .framerMotion {
        height: 100%;
    }
    .categoryName {
        color: #ff2d2e !important;
    }
    .card {
        background-color: #141414 !important;
        border-radius: 0.5rem;
        overflow: hidden;
        height: 100%;

        .card-body {
            @media (min-width: $desktop) {
                padding: 1.5rem;
            }
        }
        .card-img-top {
            border-bottom: 4px solid map-get($theme-colors, 'tertiary');
            height: calc(224px + (324 - 224) * ((100vw - 1440px) / (2560 - 1440))) !important;
            @media (max-width: $tablet) {
                height: 12rem !important;
            }
            object-fit: cover;
        }
        .card-title {
            font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (2560 - 1440))) !important;
            margin-bottom: 1rem;
            letter-spacing: 4px;
            line-height: 1.3;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .date {
            color: #d9d9d9;
            margin-bottom: 0;
            @media (min-width: $desktop) {
                font-size: calc(16px + (20 - 16) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }
    }
}

.contactUsPage {
    .contactHero {
        .row {
            @media (max-width: $mobile) {
                flex-direction: column-reverse;
            }
        }
    }
    .contactForm {
        .formContainer {
            @media (max-width: $mobile) {
                .row {
                    margin: 0.3rem;
                }
            }
            padding-top: calc(100px + 70 * (100vw - 1440px) / 1120);
            padding-bottom: calc(100px + 70 * (100vw - 1440px) / 1120);
            .titleColBackground {
                background: #262626;
            }
            .formColBackground {
                background: #141414;
            }
            p {
                font-size: 16px;
                @media (min-width: $desktop) {
                    font-size: calc(16px + (20 - 16) * ((100vw - 1440px) / (2560 - 1440))) !important;
                }
            }
            .hs-form-checkbox-display {
                align-items: baseline !important;
            }
            .hs-input[type='checkbox']:checked + span::before {
                top: 0.5rem !important;
            }
        }
    }
    .childrenContent {
        background-size: contain;
        background-color: #b3fa3a;
    }
}

.formContainer {
    .hs-main-font-element {
        h2 {
            font-size: calc(42px + 12 * (100vw - 1440px) / 1120) !important;
            letter-spacing: 3.36px !important;
            line-height: 1.2;
            strong {
                font-weight: 300 !important;
            }
        }
    }
    .hs-fieldtype-checkbox {
        span:first-child {
            font-weight: 500;
            line-height: 150%;
        }
        input {
            width: unset;
        }
    }
    .hs-fieldtype-select,
    .hs-fieldtype-text,
    .hs-fieldtype-textarea {
        margin-bottom: 2.5rem;
        select,
        input,
        textarea {
            background: transparent;
            border: 0;
            border-bottom: 1px solid #595959;
            font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (2560 - 1440))) !important;
            width: 100%;
            color: #bfbfbf;
            padding-bottom: 0.3rem;
            font-weight: 300;
        }
        select:focus-visible,
        input:focus-visible {
            outline: none;
            border-radius: 0px;
        }
        select {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23BCFC07'><polygon points='0,0 100,0 50,50'/></svg>")
                no-repeat;
            background-size: 10px;
            background-position: calc(100% - 5px) 10px;
            background-repeat: no-repeat;
        }
        textarea {
            height: 120px;
        }
        ::placeholder {
            color: #bfbfbf;
            letter-spacing: 0.1rem;
            font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (2560 - 1440))) !important;
            font-weight: 300;
        }
        :-ms-input-placeholder {
            color: #bfbfbf;
            letter-spacing: 0.1rem;
            font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (2560 - 1440))) !important;
            font-weight: 300;
        }
        ::-ms-input-placeholder {
            color: #bfbfbf;
            letter-spacing: 0.1rem;
            font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (2560 - 1440))) !important;
            font-weight: 300;
        }
    }
    .btn {
        display: flex;
        justify-content: space-between;
    }
    .hs-fieldtype-checkbox {
        label {
            color: #f0f0f0;
            font-size: calc(16px + (20 - 16) * ((100vw - 1440px) / (2560 - 1440))) !important;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: 1.6px;
        }
    }
    .hs-form-checkbox {
        margin-bottom: 0.5rem;
    }
    .hs-button {
        text-transform: uppercase;
        width: 90px;
        height: 40px;
        font-size: 1rem;
        font-weight: 500;
        margin: 2rem 0 0 0;
        line-height: 0.94;
        letter-spacing: 0.72px;
        background: white;
        border: 0;
        border-radius: 0;
        color: black;
    }
    .hs-form-checkbox-display,
    .hs-form-booleancheckbox-display {
        display: flex;
        align-items: center;
        @media (max-width: $desktop) {
            align-items: baseline;
        }
        cursor: pointer;
        position: relative;
    }
    .hs-input[type='checkbox'] + span {
        padding: 0.5rem;
    }
    .hs-input[type='checkbox'] {
        height: 16px;
        width: 16px !important;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        border: 1px solid #595959;
        outline: none;
        background-color: #141414;
        cursor: pointer;
        min-width: 16px;
    }

    .hs-input[type='checkbox']:checked {
        border: 1px solid #bcfc07;
        background-color: #262626;
    }

    .hs-input[type='checkbox']:checked + span::before {
        content: '\2713';
        display: block;
        text-align: center;
        color: #bcfc07;
        position: absolute;
        left: 0.15rem;
        top: 0.5rem;
        @media (max-width: $desktop) {
            top: 0.35rem;
        }
    }

    .hs-input[type='checkbox']:active {
        border: 2px solid #bcfc07;
    }

    .hs-richtext,
    .hs-input[type='checkbox'] + span {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 1.6px;
        font-weight: 300;
        color: #f0f0f0;
    }
}

.servicesPage {
    .heroServices {
        .btn {
            @media (max-width: $tablet) {
                width: 100%;
            }
        }
        .row {
            @media (max-width: $mobile) {
                flex-direction: column-reverse;
            }
        }
        img {
            @media (min-width: $mobile) {
                width: auto !important;
            }
        }
        .content {
            @media (min-width: $tablet) {
                padding: 0 calc(80px + (150 - 80) * ((100vw - 1440px) / (2560 - 1440))) 0
                    calc(160px + (420 - 160) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }
    }
    .servicesCTA {
        background: linear-gradient(90deg, #5a0994 0%, #9f23e1 100%);
    }
    .powerBlockchain {
        color: #000;
        h2 {
            font-size: calc(96px + (130 - 96) * ((100vw - 1440px) / (2560 - 1440))) !important;
        }
        p {
            border-bottom: 2px solid black;
            padding-bottom: 2rem;
        }
        .row {
            background-color: #3edc64;
        }
        .content {
            @media (min-width: $tablet) {
                padding: 0 4rem 0 calc(160px + (420 - 160) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }
        .childrenContent {
            background-position: initial !important;
        }
        .btn {
            @media (max-width: $mobile) {
                text-align: left;
            }
        }
    }

    .slideOffer {
        position: relative;
        overflow: hidden;
        .react-multi-carousel-track {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: row;
            position: relative;
            transform-style: preserve-3d;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            will-change: transform, transition;
        }
        .react-multi-carousel-item {
            width: 100% !important;
        }
        .card-body {
            p {
                font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
            padding: 2rem 1rem;
            @media (min-width: $tablet) {
                padding: 3rem;
            }
        }

        .react-multi-carousel-list {
            overflow-x: scroll;
            .carouselCard {
                color: black;
                border-radius: 8px;
                width: calc(450px + (700 - 450) * ((100vw - 1440px) / (2560 - 1440)));
                @media (max-width: $tablet) {
                    width: calc(420px + (500 - 420) * ((100vw - 320px) / (991 - 320)));
                }
                @media (max-width: $mobile) {
                    width: 310px;
                }
            }
            .carouselCard0 {
                background: #bcfc07;
            }
            .carouselCard1 {
                background: #ad53e5;
            }
            .carouselCard2 {
                background: #4589f6;
            }
            .carouselCard3 {
                background: #f622e6;
            }
            .carouselCard4 {
                background: #f9aaca;
            }
        }

        .carouselDragging {
            cursor: grabbing !important;
            .carouselCard {
                cursor: grabbing !important;
            }
        }

        .react-multi-carousel-list::-webkit-scrollbar {
            height: 5px;
        }

        .react-multi-carousel-list::-webkit-scrollbar-thumb {
            height: 5px;
            background-color: #fff;
        }

        .react-multi-carousel-list::-webkit-scrollbar-thumb:hover {
            background-color: #fff;
        }

        .react-multi-carousel-list::-webkit-scrollbar:vertical {
            display: none;
        }

        .carouselScrollLine {
            background-color: #fff;
            height: 1px;
            width: 100%;
            position: relative;
            top: -3px;
            z-index: -10;
        }
    }
}

.caseStudiesPage {
    padding-bottom: calc(150px + 70 * (100vw - 1440px) / 1120);
    padding-top: 0px;
    .caseStudiesPageHeader {
        @media (max-width: $mobile) {
            height: 593px;
        }
        .ContentFullScreen2 {
            height: calc(539px + (700 - 539) * ((100vw - 1440px) / (2560 - 1440)));
            background-repeat: round;
            @media (max-width: $tablet) {
                height: calc(493px + (700 - 493) * ((100vw - 768px) / (1440 - 768)));
            }
            @media (max-width: $mobile) {
                height: calc(597px + (700 - 597) * ((100vw - 375px) / (768 - 375)));
                background-repeat: unset;
                background-position: right top;
            }
        }
        .caseStudiesHeroImg {
            position: absolute;
            width: calc(750px + (1350 - 750) * ((100vw - 1440px) / (2560 - 1440)));
            height: auto;
            top: 3rem;
            right: 0;
            bottom: 0;
            margin: auto;
            z-index: 1;
            @media (max-width: $tablet) {
                top: 0rem;
                width: 750px;
                left: calc(400px + (544 - 400) * ((100vw - 768px) / (992 - 768)));
            }
            @media (max-width: $mobile) {
                display: none;
            }
        }
        .caseStudiesHero {
            .caseStudiesHeroImgMobile {
                width: calc(375px + (675 - 375) * ((100vw - 375px) / (768 - 375)));
                height: auto;
                margin-bottom: 5rem;
                @media (min-width: $mobile) {
                    display: none;
                }
                @media (max-width: $mobile) {
                    margin-left: calc(-15px - (65 - 15) * ((100vw - 576px) / (768 - 576)));
                }
                @media (max-width: 575.99px) {
                    margin-left: calc(-44px + 44 * ((100vw - 320px) / (576 - 320)));
                }
            }
            .half-title {
                font-size: calc(14px + 2 * (100vw - 1440px) / 1120) !important;
                margin-bottom: 1.25rem;
            }
            .container {
                padding-inline: 0rem;
                @media (max-width: $mobile) {
                    padding-inline: 3.5rem;
                }
            }
            h1 {
                margin-bottom: 2.5rem;
            }
            h2 {
                display: none;
            }
        }
    }
    .caseStudiesPageContent {
        .btn-outline::after {
            content: url("data:image/svg+xml,<svg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M5.00003 0.975098L5.00003 8.97959M5.00003 8.97959L8.14429 5.83534M5.00003 8.97959L1.85578 5.83534' stroke='white'/> <path d='M0.627103 9.97949L0.627103 11.9849L9.3728 11.9849L9.3728 9.97949' stroke='white'/> </svg>") !important;
        }
        .btn-secondary::after {
            content: url("data:image/svg+xml,<svg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M5.00003 0.975098L5.00003 8.97959M5.00003 8.97959L8.14429 5.83534M5.00003 8.97959L1.85578 5.83534' stroke='black'/> <path d='M0.627103 9.97949L0.627103 11.9849L9.3728 11.9849L9.3728 9.97949' stroke='black'/> </svg>") !important;
        }
    }
    .btnBlogVertical {
        margin-right: 1rem;
    }
    .BlogVertical {
        @media (max-width: $mobile) {
            padding-inline: 1rem;
        }
        .btnListMobile {
            @media (min-width: $mobile) and (max-width: $desktop) {
                flex-direction: column;
            }
            button {
                @media (min-width: $mobile) and (max-width: $desktop) {
                    width: 100%;
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
    .row {
        > * {
            padding-inline: 0px;
        }
    }
    .featuredContent {
        background: #141414;
        padding-inline: 3.5rem;
        padding-top: 7.6rem;
        padding-bottom: 7.6rem;
        @media (max-width: $tablet) {
            padding-inline: 1rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
        h4 {
            margin-bottom: 1.25rem !important;
        }
        p {
            font-size: calc(16px + 4 * (100vw - 1440px) / 1120) !important;
            margin-bottom: 1.25rem !important;
        }
    }
    .featuredImage {
        img {
            @media (min-width: $mobile) {
                object-fit: cover;
                height: 100%;
            }
        }
    }
    .caseStudyLeft {
        .featuredImage {
            img {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
        }
        .featuredContent {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
    .rowReverse {
        .row {
            flex-direction: row-reverse;
        }
        .featuredImage {
            img {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
        .featuredContent {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }
    }
}

.careersPage {
    .peopleStories {
        .react-multi-carousel-list {
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;
            z-index: 999;
        }
        .react-multi-carousel-track {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: row;
            position: relative;
            transform-style: preserve-3d;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            will-change: transform, transition;
        }
        .react-multi-carousel-item {
            transform-style: preserve-3d;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
        .arrow-right {
            @media (max-width: $tablet) {
                position: absolute;
                right: -0.5rem;
            }
            @media (min-width: $tablet) {
                position: absolute;
                right: -2.5rem;
            }
        }
        .arrow-left {
            @media (max-width: $tablet) {
                position: absolute;
                left: -0.5rem;
            }
            @media (min-width: $tablet) {
                position: absolute;
                left: -2.5rem;
            }
        }
    }
    .heroServices {
        .row {
            @media (max-width: $mobile) {
                flex-direction: column-reverse;
            }
        }
        .videoPlayer {
            .poster {
                height: 100%;
            }
        }
    }
    .Hero {
        .content {
            @media (min-width: $tablet) {
                padding: 0 calc(90px + (100 - 90) * ((100vw - 1440px) / (2560 - 1440))) 0
                    calc(145px + (400 - 145) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }
        .childrenContent {
            background-position: 50%;
            @media (max-width: $tablet) {
                background-position: 80%;
                height: 31rem;
            }
            @media (max-width: $mobile) {
                height: 19rem;
            }
        }
    }
    .JobAccordion {
        .accordion-item {
            border-radius: 0.5rem;
            overflow: hidden;
        }
        .accordion-body {
            padding: 3rem;
        }
        .accordion-header {
            :hover,
            :active {
                background: #141414;
                color: map-get($theme-colors, 'quaternary');
            }
            button {
                padding: 2rem !important;
            }
            overflow: hidden;
            h5 {
                font-weight: 300;
                line-height: 1.3;
                letter-spacing: 2.16px;
            }
            p {
                line-height: 1.24;
                letter-spacing: 2px;
                font-weight: 300;
                display: flex;
                align-items: center;
            }
        }
        .accordion-button::after {
            background-image: url('/img/accordion-arrow.png') !important;
            width: 2rem;
            height: 2rem;
        }
        .accordion-button:not(.collapsed)::after {
            transform: rotate(90deg);
        }
        .jobBody {
            color: white;
            height: 19rem;
            overflow-y: auto;
            margin-bottom: 1rem;
            ul {
                list-style: unset;
                margin-left: 2rem;
            }
            span {
                color: white !important;
            }
        }
        .jobBody::-webkit-scrollbar {
            width: 5px;
        }

        .jobBody::-webkit-scrollbar-thumb {
            width: 5px;
            background-color: #fff;
        }

        .jobBody::-webkit-scrollbar-thumb:hover {
            background-color: #fff;
        }

        .jobBody::-webkit-scrollbar:horizontal {
            display: none;
        }
    }
    .ourCulture {
        background: url('/img/our-culture-bg.webp');
        background-size: cover;
        background-position: center;
        h2 {
            font-size: calc(96px + (130 - 96) * ((100vw - 1440px) / (2560 - 1440))) !important;
        }
    }
    .AboutCasperLabs {
        .row {
            background-color: transparent !important;
        }
        h2 {
            font-size: calc(96px + (130 - 96) * ((100vw - 1440px) / (2560 - 1440))) !important;
        }
        p {
            border-bottom: 1px solid white;
            padding: 0 0 4rem;
        }
    }

    .peopleStories {
        .QuoteFull3 {
            background-color: #141414 !important;
            padding: 2rem 3rem;
            @media (max-width: $desktop) {
                padding: 2rem 1rem;
            }
            border-radius: 0.5rem;
        }
    }
    .CardHorizontal4 {
        .card {
            @media (max-width: $desktop) {
                padding: 2rem;
                .title {
                    font-size: 2.6rem !important;
                }
            }
            @media (max-width: $mobile) {
                height: 16rem;
                &:hover {
                    height: 100% !important;
                }
            }
        }
    }
}

.aboutUsPage {
    .our-leadership {
        padding-top: 4rem;
        svg {
            width: 1.625rem;
            height: auto;
        }
    }
    .card {
        @media (max-width: $desktop) {
            padding: 2rem;
            .title {
                font-size: 2.6rem !important;
            }
        }
        @media (max-width: $mobile) {
            height: 16rem;
            &:hover {
                height: 100% !important;
            }
        }
    }

    .react-multi-carousel-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        z-index: 999;
    }
    .react-multi-carousel-track {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        position: relative;
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        will-change: transform, transition;
    }
    .react-multi-carousel-item {
        transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }
    .arrow-right {
        @media (max-width: $tablet) {
            position: absolute;
            right: -1.5rem;
        }
        @media (min-width: $tablet) {
            position: absolute;
            right: -2.5rem;
        }
    }
    .arrow-left {
        @media (max-width: $tablet) {
            position: absolute;
            left: -1.5rem;
        }
        @media (min-width: $tablet) {
            position: absolute;
            left: -2.5rem;
        }
    }
    .videoPlayer {
        .poster {
            height: 100%;
        }
    }
    .aboutUsPageMain {
        overflow: hidden;
    }
    .aboutGradient {
        background: url('/img/bg-about-gradient.webp') !important;
        background-size: 100% 75% !important;
        background-repeat: no-repeat !important;
        .childrenContent {
            @media (max-width: $mobile) {
                height: 0px !important;
            }
        }
    }
    .header {
        z-index: 2;
        .row {
            @media (max-width: $mobile) {
                flex-direction: column-reverse;
            }
        }
    }
    .customerValue {
        height: calc(850px + 200 * (100vw - 1440px) / 1120) !important;
        display: flex;
        flex-direction: column;
        justify-content: center;

        z-index: 2;
        .row {
            background: transparent;
        }
        .customerValueDesc {
            text-transform: uppercase;
            cursor: pointer;
            width: fit-content;
            padding-inline: 0rem !important;
            background-color: transparent;
            margin-bottom: 0 !important;
            border: 0;
        }
    }
    .customerValueImg {
        position: absolute;
        width: auto;
        height: calc(780px + 200 * (100vw - 1440px) / 1120) !important;
        right: 0;
        top: 0;
        margin: auto;
        z-index: 1;
        @media (max-width: $tablet) {
            height: calc(513px + (700 - 513) * (100vw - 768px) / (992 - 768)) !important;
        }
        @media (max-width: $mobile) {
            display: none;
        }
    }
    .rightLightImg {
        position: absolute;
        width: auto;
        height: calc(800px + 120 * (100vw - 1440px) / 1120) !important;
        right: calc(-310px + 10 * (100vw - 1440px) / 1120) !important;
        top: -80px;
        margin: auto;
        z-index: 1;
        @media (max-width: $tablet) {
            display: none;
        }
    }
    .leftLightImg {
        position: absolute;
        width: auto;
        height: calc(1200px + 120 * (100vw - 1440px) / 1120) !important;
        left: calc(-300px + 200 * (100vw - 1440px) / 1120) !important;
        top: 200px;
        margin: auto;
        z-index: 1;
        @media (max-width: $tablet) {
            display: none;
        }
    }
    .ceoMessage {
        .childrenContent {
            height: calc(454px + 260 * (100vw - 1440px) / 1120) !important;
            @media (max-width: $mobile) {
                display: none;
            }
        }
        .row {
            background: #bcfc07;
        }
        p {
            color: black;
            font-weight: bold;
            margin-bottom: 1rem !important;
        }
        h2 {
            min-width: fit-content !important;
            margin-top: 4.75rem;
        }
        blockquote {
            margin: unset;
            padding: unset;
            quotes: '\201C''\201D''\2018''\2019';
            color: black;
            font-size: calc(42px + (54 - 42) * ((100vw - 1440px) / (2560 - 1440))) !important;
            letter-spacing: 3.36px !important;
        }

        blockquote:before {
            color: black;
            content: open-quote;
            font-size: 6em;
            line-height: 0em;
            margin-left: 0em;
            margin-right: -0.22em;
            vertical-align: -0.25em;
        }

        blockquote:after {
            display: none;
        }
    }

    .ourFounders {
        position: relative;
        padding-block: 0;
        z-index: 2;
        svg {
            @media (max-width: $tablet) {
                width: 28px !important;
                height: 100%;
            }
        }
    }
    .globalTeam {
        .row {
            background: linear-gradient(-250deg, #5a0994 29%, #9f23e1 79%) !important;
        }

        h2 {
            font-size: calc(96px + (120 - 96) * (100vw - 1440px) / 1120) !important;
            line-height: 0.94;
            letter-spacing: normal !important;
        }
        p {
            letter-spacing: 2px;
            line-height: 1.24;
            margin-bottom: 2rem !important;
        }
    }
}

.parisBlockchain {
    .caseStudiesPageContent {
        .btn-outline::after {
            content: url("data:image/svg+xml,<svg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M5.00003 0.975098L5.00003 8.97959M5.00003 8.97959L8.14429 5.83534M5.00003 8.97959L1.85578 5.83534' stroke='white'/> <path d='M0.627103 9.97949L0.627103 11.9849L9.3728 11.9849L9.3728 9.97949' stroke='white'/> </svg>") !important;
        }
        .btn-secondary::after {
            content: url("data:image/svg+xml,<svg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M5.00003 0.975098L5.00003 8.97959M5.00003 8.97959L8.14429 5.83534M5.00003 8.97959L1.85578 5.83534' stroke='black'/> <path d='M0.627103 9.97949L0.627103 11.9849L9.3728 11.9849L9.3728 9.97949' stroke='black'/> </svg>") !important;
        }
    }
    .header-section-title {
        @media (max-width: $tablet) {
            font-size: 42px !important;
        }
    }
    .hero2 {
        .boxColor {
            @media (max-width: $tablet) {
                padding: 1.5rem 0 0 0.8rem !important;
                height: 100px !important;
            }
        }
    }
    .hero1 {
        .childrenContent {
            @media (max-width: $tablet) {
                background-position: -120px;
            }
        }
    }
}

.OurLeadership {
    .imgMember {
        object-fit: cover !important;
    }
}

.bgTeam {
    background: linear-gradient(180deg, #2d2fe5 0%, #af49e6 90%);
}

.aboutUsPage {
    .ourFounders-teams-page {
        padding: 1rem 0;
    }
}

.teamsPage {
    svg {
        @media (max-width: $tablet) {
            width: 28px !important;
            height: 100%;
        }
    }
    .teamCard {
        .h6 {
            @media (max-width: $tablet) {
                font-size: calc(42px + (54 - 42) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }
    }
    .heroTeam {
        .row {
            @media (max-width: $mobile) {
                flex-direction: column-reverse;
            }
        }
        .childrenContent {
            height: calc(669px + (1080 - 669) * ((100vw - 1440px) / (2560 - 1440))) !important;
            @media (max-width: $mobile) {
                flex-direction: column-reverse;
            }
            @media (max-width: $tablet) {
                height: calc(360px + (550 - 360) * ((100vw - 320px) / (991 - 320))) !important;
            }
        }
    }
    .globalTeam {
        .content {
            @media (min-width: $desktop) {
                padding: 0 calc(80px + (120 - 80) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }
        .row {
            background: linear-gradient(-250deg, #5a0994 29%, #9f23e1 79%) !important;
            flex-direction: row-reverse;
        }

        h2 {
            font-size: calc(96px + (120 - 96) * (100vw - 1440px) / 1120) !important;
            line-height: 0.94;
            letter-spacing: normal !important;
        }
        p {
            letter-spacing: 2px;
            line-height: 1.24;
            margin-bottom: 2rem !important;
        }
    }
    .aboutCasperLabs {
        h2 {
            font-size: calc(96px + (120 - 96) * (100vw - 1440px) / 1120) !important;
            line-height: 0.94;
            letter-spacing: normal !important;
        }
        p {
            letter-spacing: 2px;
            line-height: 1.24;
            margin-bottom: 2rem !important;
        }
        .content {
            @media (min-width: $desktop) {
                padding: 0 calc(100px + (120 - 100) * ((100vw - 1440px) / (2560 - 1440))) 0
                    calc(140px + (400 - 140) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }
    }
    .ourFounders-teams-page {
        position: relative;
        padding-bottom: 0;
        z-index: 2;
    }

    .our-team {
        hr {
            opacity: 0.75;
        }
        .BadgeList {
            span {
                background-color: transparent !important;
                border-bottom: 1px solid #434343;
                padding: 1rem 1.5rem 1rem;
                border-radius: 0;
                font-weight: 300;
                font-size: 1rem !important;
                letter-spacing: 1.6px;
                &.active {
                    border-bottom: 1px solid red;
                }
            }
        }
    }
}

.legalDetail {
    padding-block: unset;
    padding-top: calc(180px + 70 * (100vw - 1440px) / 1120);
    padding-bottom: calc(100px + (250 - 100) * ((100vw - 1440px) / (2560 - 1440))) !important;
    @media (max-width: $desktop) {
        padding-bottom: calc(56px + (70 - 56) * ((100vw - 1440px) / (2560 - 1440))) !important;
    }
    .container-fluid {
        padding-inline: 0 !important;
    }
    h2 {
        margin-bottom: 0 !important;
    }
    .body {
        a {
            color: #ff2d2e;
        }
    }
    .lineBodyEnd {
        border: 1px solid #ff2d2e;
        width: 170px;
        margin-top: 5rem;
    }
}

.custom-modal {
    font-family: 'CasperSansHubspot' !important;
    .container.modal-container {
        p,
        label,
        input {
            font-family: 'CasperSansHubspot' !important;
        }
    }
}

.custom-modal {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
    .legal-consent-container .hs-form-booleancheckbox-display > span {
        margin-left: 0;
    }
    .input {
        margin-right: 30px !important;
        @media (min-width: $desktop) {
            margin-right: 40px !important;
        }
    }
    .form-columns-1 {
        .input {
            margin-right: 0 !important;
        }
    }
    .container.modal-container {
        max-width: 58rem !important;
        background-color: #141414;
        padding: 56px 7rem;
        @media (max-width: $tablet) {
            padding: 56px !important;
        }
        border-radius: 0.5rem;
        @media (max-width: $mobile) {
            padding: 24px 8px !important;
        }
        .hs-form-field {
            label {
                font-size: calc(20px + (24 - 20) * ((100vw - 1440px) / (2560 - 1440)));
                color: #bfbfbf;
                line-height: 1.24;
                letter-spacing: 2px;
            }
        }
        .hs-error-msg {
            font-size: 0.875em !important;
            font-weight: 300;
            color: #bfbfbf;
        }
        p {
            font-size: 14px !important;
            line-height: 1.21 !important;
            letter-spacing: 1.4px !important;
            color: #f0f0f0 !important;
        }
        ul.inputs-list {
            margin: 12px 0px;
        }

        fieldset {
            max-width: unset;
            &.form-columns-1:last-of-type {
                margin-top: 24px;
            }
        }

        .submitted-message {
            text-align: center;
        }
    }

    .modal-content {
        background-color: transparent;
        &::before {
            content: '';
            width: 100vw;
            height: 100%;
            position: absolute;
            background-color: #000000cc;
            z-index: -1;
        }
    }
}

.toast {
    position: fixed !important;
    width: 90vw !important;
    bottom: 1rem !important;
    background-color: #000 !important;
    color: white !important;
    border-style: solid !important;
    border-width: thin !important;
    border-radius: 8px !important;
    border: 1px solid #595959 !important;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: 1.6px;
    left: 50%;
    transform: translate(-50%, 0);
    .toast-header {
        background-color: #262626 !important;
    }
    .toast-body {
        display: flex;
        justify-content: space-between;
        padding: 2rem;
        align-items: center;
        p {
            margin-bottom: 0rem;
        }
    }
}

.cookieConsent {
    max-height: 70vh;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    align-items: center !important;
    position: fixed !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    width: 40vw !important;
    left: unset !important;
    border-radius: 5px !important;
    border-style: solid !important;
    border-width: thin !important;
    padding: 2rem !important;
    background-color: #000 !important;
    @media (max-width: $tablet) {
        overflow: auto;
        width: 100% !important;
        right: unset !important;
        bottom: 0 !important;
        border-style: unset !important;
        border-width: unset !important;
        border-radius: unset !important;
        border-top-style: solid !important;
        border-top-width: thin !important;
    }
    .cookieText {
        flex: unset !important;
        letter-spacing: 1px !important;
        @media (min-width: $desktop) {
            font-size: calc(16px + (20 - 14) * ((100vw - 1440px) / (2560 - 1440))) !important;
        }
        .settings {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .cookieActions {
        display: flex;
        flex-direction: row-reverse;
        width: 100%;
        justify-content: flex-end;
    }
    #rcc-decline-button {
        background: transparent !important;
        border: 1px solid white !important;
        padding-right: 1.3rem !important;
        padding-left: 1rem !important;
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
        flex: unset !important;
        @media (max-width: $mobile) {
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }
    }
    #rcc-confirm-button {
        padding-right: 1.3rem !important;
        padding-left: 1rem !important;
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;
        background: #fff !important;
        flex: unset !important;
        @media (max-width: $mobile) {
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }
    }
}

.cookieConsent::-webkit-scrollbar {
    width: 5px;
}

.cookieConsent::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #fff;
    border-radius: 5px;
}

.cookieConsent::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
}

.cookieConsent::-webkit-scrollbar:horizontal {
    display: none;
}

.cookieSettingsModal {
    max-width: unset !important;
    z-index: 1000;
    max-height: 70vh;
    overflow: auto;
    border-color: #fff !important;
    button {
        margin-top: 30px;
        align-self: flex-start;
    }
    form {
        width: 100%;
        align-self: flex-start;
    }
    h3 {
        align-self: flex-start;
    }
    .cookieDescText {
        letter-spacing: 1px !important;
        @media (min-width: $desktop) {
            font-size: calc(14px + (20 - 14) * ((100vw - 1440px) / (2560 - 1440))) !important;
        }
    }
    .form-check {
        position: relative;
        input[type='checkbox'] + label {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            letter-spacing: 1px !important;
            @media (min-width: $desktop) {
                font-size: calc(16px + (20 - 14) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }
        input[type='checkbox'] {
            height: 16px;
            width: 16px !important;
            background-image: none !important;
            border-radius: 0;
            margin-top: 0.7rem;
            -webkit-appearance: none !important;
            -moz-appearance: none !important;
            -o-appearance: none !important;
            appearance: none !important;
            border: 1px solid #595959;
            outline: none;
            background-color: #141414;
            cursor: pointer;
            min-width: 16px;
        }

        input[type='checkbox']:checked {
            border: 1px solid #bcfc07;
            background-color: #262626;
        }

        input[type='checkbox']:checked + label::before {
            content: '\2713';
            display: block;
            text-align: center;
            color: #bcfc07;
            position: absolute;
            left: 0.15rem;
            top: 0.5rem;
            @media (min-width: $desktop-xl) {
                top: calc(8px - (8 - 3) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
            @media (max-width: $tablet) {
                top: calc(8.8px - (8.8 - 8) * ((100vw - 1440px) / (2560 - 1440))) !important;
            }
        }

        input[type='checkbox']:active {
            border: 2px solid #bcfc07;
        }

        input[type='checkbox'] + label {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 1.6px;
            font-weight: 300;
            color: #f0f0f0;
        }
    }
}

.cookieSettingsModal::-webkit-scrollbar {
    width: 5px;
}

.cookieSettingsModal::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #fff;
    border-radius: 5px;
}

.cookieSettingsModal::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
}

.cookieSettingsModal::-webkit-scrollbar:horizontal {
    display: none;
}

.heroSlide {
    .casperLogos {
        svg {
            width: calc(240px + (280 - 240) * ((100vw - 1440px) / (2560 - 1440)));
        }
    }
}

.ctaSection {
    .h1 {
        @media (max-width: $tablet) {
            font-size: 66px !important;
        }
        @media (max-width: $mobile) {
            font-size: 58px !important;
        }
    }
}

.industryPage {
    &.financialServices {
        .industryHero {
            background: linear-gradient(90deg, #3edc64 0%, #bcfc07 50%);
        }
    }
    &.retail {
        .industryHero {
            background: linear-gradient(90deg, #f9aaca 0%, #9f23e1 50%);
        }
    }
    &.government {
        .industryHero {
            background: linear-gradient(90deg, #ff2d33 0%, #f622e6 50%);
        }
    }
    &.entertainment {
        .industryHero {
            background: linear-gradient(90deg, #9f23e1 0%, #ff2d2e 50%);
            h1 {
                font-size: calc(86px + (120 - 86) * ((100vw - 1440px) / (2560 - 1440))) !important;
                @media (max-width: $tablet) {
                    font-size: 4rem !important;
                }
            }
        }
    }
    &.supply {
        .industryHero {
            background: linear-gradient(90deg, #3edc64 0%, #f622e6 50%);
            h1 {
                font-size: calc(86px + (120 - 86) * ((100vw - 1440px) / (2560 - 1440))) !important;
                @media (max-width: $tablet) {
                    font-size: 4rem !important;
                }
            }
        }
        .industryImg {
            @media (max-width: $tablet) {
                top: 15.5rem;
            }
        }
    }
    &.ai {
        .industryHero {
            background: linear-gradient(110deg, #9f23e1 0%, #3edc64 50%);
            h1 {
                font-size: calc(72px + (110 - 72) * ((100vw - 1440px) / (2560 - 1440))) !important;
                @media (max-width: $tablet) {
                    font-size: 54px !important;
                }
                @media (max-width: $mobile) {
                    font-size: 49px !important;
                }
            }
        }
        .ctaSection {
            .cta {
                background-color: #2230f0;
                p {
                    font-size: calc(23px + (32 - 23) * ((100vw - 1440px) / (2560 - 1440)));
                    @media (max-width: $desktop-xl) {
                        max-width: 300px;
                    }
                    @media (min-width: $desktop-xl) {
                        max-width: 400px;
                    }
                    line-height: 1.3;
                }
            }
        }
        .industryImg {
            @media (max-width: $tablet) {
                top: 15.5rem;
            }
        }
    }
    .blogContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        @media (max-width: $desktop) {
            margin: 0 1rem;
        }
        @media (max-width: $tablet) {
            grid-template-columns: 1fr 1fr;
            > div:first-child {
                grid-column: span 2;
            }
        }
        @media (max-width: $mobile) {
            grid-template-columns: 1fr;
            > div:first-child {
                grid-column: span 1;
            }
        }

        gap: 1rem;
    }
    .BlogHorizontal {
        .card {
            .card-img-top {
                @media (max-width: $tablet) {
                    height: 18rem !important;
                    object-position: bottom !important;
                }
                @media (max-width: $mobile) {
                    height: 14rem !important;
                }
            }
        }
    }

    .BlogVertical {
        .featuredContent {
            background: radial-gradient(
                92.74% 338.49% at 82.31% 100%,
                rgba(249, 170, 202, 0.31) 0%,
                rgba(79, 40, 43, 0) 100%
            );
            .btn-outline::after {
                content: url("data:image/svg+xml,<svg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M5.00003 0.975098L5.00003 8.97959M5.00003 8.97959L8.14429 5.83534M5.00003 8.97959L1.85578 5.83534' stroke='white'/> <path d='M0.627103 9.97949L0.627103 11.9849L9.3728 11.9849L9.3728 9.97949' stroke='white'/> </svg>") !important;
            }
            .btn-secondary::after {
                content: url("data:image/svg+xml,<svg width='10' height='13' viewBox='0 0 10 13' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M5.00003 0.975098L5.00003 8.97959M5.00003 8.97959L8.14429 5.83534M5.00003 8.97959L1.85578 5.83534' stroke='black'/> <path d='M0.627103 9.97949L0.627103 11.9849L9.3728 11.9849L9.3728 9.97949' stroke='black'/> </svg>") !important;
            }
        }
    }
    .caseStudiesPage {
        .rowReverse {
            .featuredImage {
                img {
                    @media (max-width: $mobile) {
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 0;
                    }
                }
            }

            .featuredContent {
                @media (max-width: $mobile) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 8px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 8px;
                }
            }
        }

        .row {
            .featuredImage {
                img {
                    @media (max-width: $mobile) {
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 0;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 0;
                    }
                }
            }

            .featuredContent {
                @media (max-width: $mobile) {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 8px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 8px;
                }
            }
        }
    }
    .industriesCTA {
        .carousel-item {
            background: url('/img/industries-cta-bg.webp') !important;
        }
    }

    .bannerSlider {
        .carousel-item {
            height: 20rem !important;
            padding: 0 0 0 !important;
        }
    }
}

/* Newsletter */

.newsLetter2 {
    form {
        @media (min-width: $mobile) {
            display: grid;
            grid-template-columns: 4fr 1.8fr;
            grid-gap: 1rem;
            align-items: flex-start;
            margin-top: 0.5rem;
        }
    }
    label:not(.hs-error-msg) {
        display: none !important;
    }
    h3 {
        font-size: calc(42px + (54 - 42) * ((100vw - 1440px) / (2560 - 1440))) !important;
        letter-spacing: 3.36px !important;
    }
    .submitted-message {
        p {
            margin-bottom: 0rem !important;
        }
    }
    background: linear-gradient(280deg, #bcfc07 41.67%, #a2db00 99.76%);
    color: black;
    .input {
        align-self: flex-end;
        p {
            font-size: calc(1rem + 0.3vw) !important;
        }
    }
    .hs-input {
        background: transparent !important;
        border: 0 !important;
        font-weight: 300 !important;
        border-bottom: 1px solid !important;
        font-size: calc(1rem + 0.3vw) !important;
        color: black !important;
        align-self: center;
        margin-top: 0.5rem;
        @media (max-width: $mobile) {
            margin-bottom: 2rem !important;
        }
    }
    .hs-input::placeholder {
        color: black !important;
        letter-spacing: 0.1rem;
    }
    .hs-input::-ms-input-placeholder {
        color: black !important;
        letter-spacing: 0.1rem;
    }
    .hs-input::-ms-input-placeholder {
        color: black !important;
        letter-spacing: 0.1rem;
    }
    .hs-fieldtype-text {
        margin-bottom: 0rem !important;
    }
    .btn {
        display: flex;
        justify-content: space-between;
    }
    .hs-button {
        margin-top: 0 !important;
        display: flex;
        justify-content: space-between;
        color: white;
        background-color: #000;
        padding: 0.75rem;
        width: 100%;
        font-weight: 800;
        height: 48px;
        line-height: inherit;
        &::after {
            @include arrow-color(white);
        }
    }
    .hs-button {
        &:after {
            margin-left: 0.5rem;
            transition: transform 0.3s;
            display: inline-block;
        }
    }
    .inputArrow {
        position: absolute;
        right: 1rem;
        bottom: 0.7rem;
        svg {
            width: 1rem;
            height: auto;
        }
    }
}
