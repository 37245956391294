@import '../../../../styles/theme/breakpoints.scss';
.cookieConsent {
    max-height: 70vh;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: nowrap !important;
    align-items: center !important;
    position: fixed !important;
    right: 0.5rem !important;
    bottom: 0.5rem !important;
    width: 40vw !important;
    left: unset !important;
    border-radius: 5px !important;
    border-style: solid !important;
    border-width: thin !important;
    padding: 2rem !important;
    background-color: #000 !important;
    @media (max-width: $tablet) {
        overflow: auto;
        width: 100% !important;
        right: unset !important;
        bottom: 0 !important;
        border-style: unset !important;
        border-width: unset !important;
        border-radius: unset !important;
        border-top-style: solid !important;
        border-top-width: thin !important;
    }
}

.declineButton {
    background: transparent !important;
    border: 1px solid white !important;
    padding-right: 1.3rem !important;
    padding-left: 1rem !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    flex: unset !important;
    @media (max-width: $mobile) {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
}
.confirmButton {
    padding-right: 1.3rem !important;
    padding-left: 1rem !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    background: #fff !important;
    flex: unset !important;
    @media (max-width: $mobile) {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
}

.cookieText {
    flex: unset !important;
    letter-spacing: 1px !important;
    @media (min-width: $desktop) {
        font-size: calc(16px + (20 - 14) * ((100vw - 1440px) / (2560 - 1440))) !important;
    }
    .settings {
        cursor: pointer;
        text-decoration: underline;
    }
}
.cookieActions {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: flex-end;
}

.cookieConsent::-webkit-scrollbar {
    width: 5px;
}

.cookieConsent::-webkit-scrollbar-thumb {
    width: 5px;
    background-color: #fff;
    border-radius: 5px;
}

.cookieConsent::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
}

.cookieConsent::-webkit-scrollbar:horizontal {
    display: none;
}
