html,
body {
    padding: 0;
    margin: 0;
    font-family: 'CasperSans';
    font-weight: 300;
    scroll-behavior: auto !important;
}

* {
    box-sizing: border-box;
}

section {
    padding-block: calc(180px + (300 - 180) * ((100vw - 1440px) / (2560 - 1440)));
}

.bg-dark {
    background-color: #000;
}

.bg-dark-grey {
    background-color: #1a1a1a;
}

.bg-gradient-purple {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),
        linear-gradient(0deg, #9f23e1 31.27%, #5a0994 105.32%);
}

.bg-gradient-blue {
    background: linear-gradient(0deg, #2230f0 0.08%, #161f9d 100.08%);
}

.bg-gradient-blue-purple {
    background: linear-gradient(360deg, #2230f0 39.51%, #8e27e7 100%);
}

.bg-gradient-brown {
    background: linear-gradient(0deg, #4f282b 39.18%, #291315 100%);
}

.section-title-h2 {
    max-width: calc(30rem + 20vw) !important;
    letter-spacing: 3px;
    margin-bottom: 3rem;
}
.header-section-title {
    font-size: calc(66px + (86 - 66) * ((100vw - 1440px) / (2560 - 1440))) !important;
    max-width: calc(30rem + 20vw) !important;
    letter-spacing: 1.98px !important;
    margin-bottom: 3rem;
    font-weight: 300;
}

span.text-red {
    color: map-get($theme-colors, 'tertiary');
}

span.text-green {
    color: map-get($theme-colors, 'quaternary');
}

.text-green {
    color: map-get($theme-colors, 'quaternary');
}

.text-light-green {
    color: #3edc64;
}

.text-red {
    color: map-get($theme-colors, 'tertiary');
}

.text-purple {
    color: #f622e6;
}

.text-dark-purple {
    color: #9f23e1;
}

.text-yellow {
    color: #ffc107;
}

.text-dark {
    color: map-get($theme-colors, 'primary');
}

span.text-purple {
    color: #f622e6;
}

.cursor-pointer {
    cursor: pointer;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    margin-bottom: 1rem;
}

h1,
h2 {
    letter-spacing: 0;
    line-height: 0.94;
}

h4 {
    letter-spacing: 0.16rem;
}

h5,
.h5 {
    letter-spacing: 1.62px;
}

h6 {
    letter-spacing: 0.125rem;
}

p,
li {
    letter-spacing: 0.1rem;
}

.half-title {
    text-transform: uppercase;
    letter-spacing: 3.36px !important;
    font-size: 0.875rem;
    @media (min-width: $desktop) {
        font-size: calc(14px + (18 - 14) * ((100vw - 1440px) / (2560 - 1440))) !important;
    }
    color: white;
}

.height-zero {
    height: 0px !important;
}

// Marquee LogoSlider Animation

$count: 0;

@while $count < 200 {
    @keyframes scroll-#{$count} {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(calc(-300px * $count));
        }
    }

    $count: $count + 1;
}

.slider {
    height: 100px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: auto;
    display: flex;
    align-items: center;

    .slideTrack {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slide {
        img {
            width: auto;
            height: 100%;
        }
        height: auto;
        width: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.alert-dark {
    border-style: solid;
    border-width: 1px;
    border-color: white;
    border-radius: unset;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .alert-exclamation {
        height: 3rem;
        width: 3rem;
    }
}

// Overwrites a style from a third-party library.
.infinite-scroll-component {
    overflow: hidden !important;
}
